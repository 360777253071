import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import Layout from "../components/layout";
import SEO from "../components/seo";

import Slider from "react-slick";
import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";

const settings = {
  dots: false,
  infinite: true,
  swipeToSlide: true,
  // variableWidth: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  // centerMode: true,
};

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark;
    const siteTitle = this.props.data.site.siteMetadata.title;

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />
        <article className={`post-content ${post.frontmatter.thumbnail || `no-image`}`}>
          <div className="carousel-container">
            <Slider {...settings} className="overflow-hidden">
              {/* {console.log(post.frontmatter.images)} */}
              {post.frontmatter.images.map((item, index) => {
                return (
                  <GatsbyImage
                    key={index}
                    image={item.childImageSharp.gatsbyImageData}
                    alt="imagenes del proyecto"
                    className="carousel-image-content"
                  />
                );
              })}
            </Slider>
          </div>

          <header className="post-content-header">
            <h1 className="post-content-title">{post.frontmatter.title}</h1>
          </header>

          {post.frontmatter.description && (
            <p className="post-content-excerpt">{post.frontmatter.description}</p>
          )}

          {/* {post.frontmatter.thumbnail && (
            <div className="post-content-image">
              <GatsbyImage
                image={post.frontmatter.thumbnail.childImageSharp.gatsbyImageData}
                className="kg-image"
                alt={post.frontmatter.title} />
            </div>
          )} */}
          {/* {console.log(post.html)} */}

          <div className="post-content-body" dangerouslySetInnerHTML={{ __html: post.html }} />

          <footer className="post-content-footer">
            <div className="datasheet">
               
              <p className="year">
                <b>Año: </b>
                {post.frontmatter.year}
              </p>
              <p className="location">
                <b>Lugar: </b>
                {post.frontmatter.location}
              </p>
              <p className="area">
                <b>Área: </b>
                {post.frontmatter.area}
              </p>
              <p className="type">
                <b>Alcance: </b>
                {post.frontmatter.type}
              </p>
              {post.frontmatter.collab ? (
                <p className="type">
                  <b>Colaboradores: </b>
                  {post.frontmatter.collab}
                </p>
              ) : (
                <p>{post.frontmatter.collab}</p>
              )}
            </div>
            {/* There are two options for how we display the byline/author-info.
        If the post has more than one author, we load a specific template
        from includes/byline-multiple.hbs, otherwise, we just use the
        default byline. */}
          </footer>
        </article>
      </Layout>
    );
  }
}

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        images {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              width: 640
              height: 480
              placeholder: BLURRED
              transformOptions: { fit: CONTAIN }
              backgroundColor: "#3b3b3b"
            )
          }
        }
        thumbnail {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
        year
        location
        area
        type
        collab
      }
    }
  }
`;
